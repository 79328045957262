<template>
  <el-form :model="form" label-width="160px" :disabled='isPlanConfirm'>
    <el-table :data="moneylist" border style="width: 100%; margin-top: 20px">
      <el-table-column prop="name" label="筹资渠道" />
      <el-table-column prop="amount1" label="资金提供方" />
      <el-table-column prop="amount2" label="金额(单位：万元)">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <el-input v-model="form[scope.row.amount2]" />
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="amount3" label="占投资总额比例">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <el-input v-model="form[scope.row.amount3]">
              <template #append>%</template>
            </el-input>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-form-item>
      <el-button type="primary" @click="onSubmit">暂存</el-button>
    </el-form-item>
  </el-form>
</template>

<script setup>
import { ref, reactive, getCurrentInstance } from 'vue'
import { detailMoneySource, submitMoneySource } from '@/api/cyds';
import { ElMessage, ElMessageBox } from 'element-plus'
const props = defineProps({
  PlanEntrepreneurshipId: {
    type: String,
    default: ''
  }, isPlanConfirm: {
    type: String,
    default: ''
  },
})
const moneylist = reactive([
  {
    name: '自有资金',
    amount1: '股东',
    amount2: 'sourceShareholder',
    amount3: 'shareholderRate',
  }, {
    name: '私人拆借',
    amount1: '亲属、朋友',
    amount2: 'sourceFriend',
    amount3: 'friendRate',
  }, {
    name: '银行贷款',
    amount1: '银行',
    amount2: 'sourceBank',
    amount3: 'bankRate',
  }, {
    name: '政府小额贷款',
    amount1: '政府相关部门',
    amount2: 'sourceGovernment',
    amount3: 'governmentRate',
  }
])
const form = reactive({})
console.log(props)
detailMoneySource({
  raceId: props.PlanEntrepreneurshipId
}).then(res => {
  console.log(res)
  if (res.data) {
    Object.assign(form, res.data)
  }
})
const onSubmit = () => {
  submitMoneySource(form).then(res => {
    if (res.code == 200) {
      detailMoneySource({
        raceId: props.PlanEntrepreneurshipId
      }).then(res => {
        console.log(res)
        if (res.data) {
          Object.assign(form, res.data)
        }
      })
      ElMessage({
        message: '暂存成功.',
        type: 'success',
      })
    } else {
      ElMessage({
        message: '参数错误.',
        type: 'error',
      })
    }
  })
}
</script>

<style>
</style>