<template>
  <!-- 创业计划书 -->
  <el-form :model="form" label-width="160px">
    <div class="plantitle">
      执行摘要
    </div>
    <el-divider />

    <div class="plantitle">
      营销策略
    </div>
    <el-divider />
    <div class="plantitle">
      管理团队
    </div>
    <el-form-item label="">
      <div v-for="item in 2" :key="item">
        <el-row>
          <el-col :span="8">
            <el-form-item label="姓名" label-width="50px">
              <el-input v-model="form.name" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="年龄" label-width="50px">
              <el-input v-model="form.name" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="职务" label-width="50px">
              <el-input v-model="form.name" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="学历及专业" label-width="100px">
              <el-input v-model="form.name" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="主要工作经历" label-width="100px">
              <el-input v-model="form.name" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="优势专长" label-width="100px">
              <el-input v-model="form.name" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider />
      </div>
      <el-row>
        <el-col :span="6">
          <el-button class="mt-2" style="margin-top:20px;">增加</el-button>
        </el-col>
        <el-col :span="6">
        </el-col>
      </el-row>
    </el-form-item>
    <el-divider />
    <div class="plantitle">
      财务分析报告
    </div>
    <el-form-item label="启动资金来源 ">
      <el-table :data="moneylist" border style="width: 100%; margin-top: 20px">
        <el-table-column prop="name" label="筹资渠道" />
        <el-table-column prop="amount1" label="资金提供方" />
        <el-table-column prop="amount2" label="金额(单位：万元)">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <el-input v-model="scope.amount2" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="amount3" label="占投资总额比例">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <el-input v-model="scope.amount3" />
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-form-item>
    <el-form-item label="最近年度利润">
      <el-table :header-cell-style='headFirst' :data="profitList" :span-method="objectSpanMethod" border
        style="width: 100%; margin-top: 20px">
        <el-table-column label="项  目" align="center">
          <el-table-column prop="name2" label="项  目">
            <template #default="scope">
              <div style="display: flex; align-items: center">
                {{scope.row.name}}
              </div>
            </template>
          </el-table-column>
          <el-table-column>
            <template #default="scope">
              <div style="display: flex; align-items: center">
                {{scope.row.name2}}
              </div>
            </template>
          </el-table-column>

        </el-table-column>
        <el-table-column prop="amount1" label="19年期末余额">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <el-input v-model="scope.amount1" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="amount2" label="20年期末余额">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <el-input v-model="scope.amount2" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="amount3" label="21年期末余额">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <el-input v-model="scope.amount3" />
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-form-item>

    <el-divider />
    <div class="plantitle">
      融资需求
    </div>
    <el-form-item label="当前是否有融资">
      <el-radio-group v-model="form.resource">
        <el-radio label="是" />
        <el-radio label="否" />
      </el-radio-group>
    </el-form-item>
    <el-form-item label="计划融资方式">
      <el-radio-group v-model="form.resource">
        <el-radio label="股权融资" />
        <el-radio label="债券融资" />
      </el-radio-group>
    </el-form-item>

    <el-form-item label="时间金额 ">
      <el-table :data="Financingdemandlist" border style="width: 100%; margin-top: 20px">
        <el-table-column prop="amount2" label="计划融资时间">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <el-date-picker v-model="scope.row.amount2" type="date" placeholder="请选择日期" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="amount3" label="计划融资金额">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <el-input v-model="scope.row.amount3" />
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-form-item>

    <el-form-item label="是否有融资经历 ">
      <el-radio-group v-model="form.resource">
        <el-radio label="是" />
        <el-radio label="否" />
      </el-radio-group>
    </el-form-item>
    <el-form-item label="时间金额 ">
      <el-table :data="Financingdemandlist" border style="width: 100%; margin-top: 20px">
        <el-table-column prop="amount2" label="计划融资时间">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <el-date-picker v-model="scope.row.amount2" type="date" placeholder="请选择日期" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="amount3" label="计划融资金额">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <el-input v-model="scope.row.amount3" />
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-form-item>

    <el-divider />
    <el-form-item>
      <el-button type="primary" @click="onSubmit">保存</el-button>
    </el-form-item>
  </el-form>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { client } from '@/utils/ali-oss.js'  // 引入oss.js文件
import CanvasSign, { ICanvasSign } from 'vue-canvas-sign'
const blankimg = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQYV2NgAAIAAAUAAarVyFEAAAAASUVORK5CYII='
const imgSrc = ref(blankimg)
const value = ref([])
const activeName = ref('first')
const canvasSign2 = ref()
const form = reactive({
  type: [],
  resource: []
})
const moneylist = reactive([
  {
    name: '自有资金',
    amount1: '股东',
    amount2: '',
    amount3: '',
  }, {
    name: '私人拆借',
    amount1: '亲属、朋友',
    amount2: '',
    amount3: '',
  }, {
    name: '银行贷款',
    amount1: '银行',
    amount2: '',
    amount3: '',
  }, {
    name: '政府小额贷款',
    amount1: '政府相关部门',
    amount2: '',
    amount3: '',
  }
])
const profitList = reactive([
  {
    name: '一、主营业务收入',
    name2: '',
    amount1: '',
    amount2: '',
    amount3: '',
  }, {
    name: '加：其他收入',
    name2: '',
    amount1: '',
    amount2: '',
    amount3: '',
  }, {
    name: '减：主营业务成本',
    name2: '生产/采购成本',
    amount1: '',
    amount2: '',
    amount3: '',
  }, {
    name: '营业税金及附加(按5.5%计算)',
    name2: '',
    amount1: '',
    amount2: '',
    amount3: '',
  }, {
    name: '变动销售费用',
    name2: '销售提成',
    amount1: '',
    amount2: '',
    amount3: '',
  }, {
    name: '边际贡献率(%)=（主营业务收入-主营业务成本-营业税金-销售提成）/主营业务收入',
    name2: '',
    amount1: '',
    amount2: '',
    amount3: '',
  }, {
    name: '固定销售费用',
    name2: '宣传推广费',
    amount1: '',
    amount2: '',
    amount3: '',
  }, {
    name: '管理费用',
    name2: '场地租金',
    amount1: '',
    amount2: '',
    amount3: '',
  }, {
    name: '',
    name2: '员工薪酬',
    amount1: '',
    amount2: '',
    amount3: '',
  }, {
    name: '',
    name2: '办公用品及耗材',
    amount1: '',
    amount2: '',
    amount3: '',
  }, {
    name: '',
    name2: '水、电、交通差旅费',
    amount1: '',
    amount2: '',
    amount3: '',
  }, {
    name: '',
    name2: '固定资产折旧',
    amount1: '',
    amount2: '',
    amount3: '',
  }, {
    name: '',
    name2: '其他管理费用',
    amount1: '',
    amount2: '',
    amount3: '',
  }, {
    name: '财务费用',
    name2: '利息支出',
    amount1: '',
    amount2: '',
    amount3: '',
  }, {
    name: '二、营业利润',
    name2: '',
    amount1: '',
    amount2: '',
    amount3: '',
  }, {
    name: '减：所得税费用（按25％计算）',
    name2: '',
    amount1: '',
    amount2: '',
    amount3: '',
  }, {
    name: '三、净利润',
    name2: '',
    amount1: '',
    amount2: '',
    amount3: '',
  }, {
    name: '备注：员工薪酬包括企业主薪酬和职工薪酬，本计划书所提到的员工薪酬都符合该条件。',
    name2: '',
    amount1: '',
    amount2: '',
    amount3: '',
  },
])
//表格合并
const objectSpanMethod = ({ row, colunm, rowIndex, columnIndex }) => {
  if (!row.name2) {
    if (columnIndex == 0) {
      return {
        rowspan: 1,
        colspan: 2,
      }
    } else if (columnIndex == 1) {
      return [0, 0];
    }
  }
  if (row.name == '管理费用') {
    if (columnIndex == 0) {
      return {
        rowspan: 6,
        colspan: 1,
      }
    }
  }
  if (row.name == '') {
    if (columnIndex == 0) {
      return [0, 0];
    }
  }
}
//合并表头
const headFirst = ({ row, colunm, rowIndex, columnIndex }) => {
  if (rowIndex === 1) {
    //这里为了是将第二列的表头隐藏，就形成了合并表头的效果
    return { display: 'none' }

  }
}


//融资需求
const Financingdemandlist = reactive([
  {
    amount2: '',
    amount3: '',
  }, {
    amount2: '',
    amount3: '',
  }, {
    amount2: '',
    amount3: '',
  }, {
    amount2: '',
    amount3: '',
  }
])




//所属单位
const zoneoptions = ref([])
const dialogTableVisible = ref(false)
// 区下拉框
const districtOptionDistrict = ref([
  {
    districtName: '奎文',
    districtCode: 'kuiwen'
  }
])
const fileList = ref([
  // {
  //   name: 'food2.jpeg',
  //   url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100',
  // },
])
// 使用slot的clear方法
const clearWithSlotHandle = (clear) => {
  clear && clear() // 清空画布
  imgSrc.value = blankimg // 清空图片
}
// slot中save方法回调
const saveCallback = (imgBase64) => {
  imgSrc.value = imgBase64 || blankimg
  console.log(imgSrc.value)
}
const autograph = () => {
  dialogTableVisible.value = true
}
const onSubmit = () => {
  console.log('submit!')
}
</script>

<style>
.border {
  border: 1px solid #000;
}
.plantitle {
  font-size: 26px;
  margin-bottom: 10px;
}
</style>