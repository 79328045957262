<template>
  <div style="margin:0 auto;width:900px;min-height:400px">

    <el-dialog>
      <BusinessPlan></BusinessPlan>
    </el-dialog>
    <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
      <el-tab-pane label="潍坊市2022年创业大赛报名" name="first">
        <el-button type="primary" @click="dialogVisible= true">我要报名</el-button>
        <el-button type="primary" @click='chuangyejihua(1)'>创业计划书</el-button>
        <el-dialog v-model="dialogVisible" title="报名表" destroy-on-close width="60%" :close-on-click-modal='false'
          :before-close="handleClose">
          <DetailEnterprise></DetailEnterprise>
        </el-dialog>
        <el-dialog v-model="showPlanEntrepreneurship" destroy-on-close title="创业计划书" width="1000px">

          <PlanEntrepreneurship type='1' :PlanEntrepreneurshipId='PlanEntrepreneurshipId'
            v-if="showPlanEntrepreneurship">
          </PlanEntrepreneurship>
        </el-dialog>

      </el-tab-pane>
      <el-tab-pane label="第七届潍坊市大学生创新创业大赛报名" name="second">
        <el-button type="primary" @click="dialogVisible2= true">我要报名</el-button>
        <el-button type="primary" @click='chuangyejihua(2)'>创业计划书</el-button>
        <el-dialog v-model="dialogVisible2" title="报名表" destroy-on-close width="60%" :close-on-click-modal='false'
          :before-close="handleClose">
          <DetailEnterprise dsc='1'></DetailEnterprise>
        </el-dialog>
        <el-dialog v-model="showPlanEntrepreneurship2" destroy-on-close title="创业计划书" width="1000px">

          <PlanEntrepreneurship type='2' :PlanEntrepreneurshipId='PlanEntrepreneurshipId2'
            v-if="showPlanEntrepreneurship2">
          </PlanEntrepreneurship>
        </el-dialog>
      </el-tab-pane>
      <el-tab-pane label="创业导师报名" name="second2">
        <el-button type="primary" @click="dialogVisible3= true">我要报名</el-button>
        <el-dialog v-model="dialogVisible3" title="报名表" destroy-on-close width="60%" :close-on-click-modal='false'
          :before-close="handleClose">
          <Tutor></Tutor>
        </el-dialog>
      </el-tab-pane>
      <el-tab-pane label="返乡农民工报名" name="second3">
        <el-button type="primary" @click="dialogVisible4= true">我要报名</el-button>
        <el-dialog v-model="dialogVisible4" title="报名表" destroy-on-close width="60%" :close-on-click-modal='false'
          :before-close="handleClose">
          <Farmer></Farmer>
        </el-dialog>
      </el-tab-pane>
    </el-tabs>
  </div>

  <el-dialog v-model="dialogTableVisible" title="签名" width="1000px">
    <CanvasSign ref="canvasSign2" :height="400" :width="800" :line-width="10">
      <template v-slot="{ save, clear }">
        <button @click="() => save(saveCallback)">保存</button>
        <button @click="() => clearWithSlotHandle(clear)">清除</button>
      </template>
    </CanvasSign>
  </el-dialog>

</template>
<script  setup>
import { ref, reactive } from 'vue'
import { client } from '@/utils/ali-oss.js'  // 引入oss.js文件
import CanvasSign, { ICanvasSign } from 'vue-canvas-sign'
import BusinessPlan from './components/BusinessPlan.vue'
import DetailEnterprise from './components/DetailEnterprise.vue'
import Tutor from './components/Tutor.vue'
import Farmer from './components/Farmer.vue'
import PlanEntrepreneurship from './components/PlanEntrepreneurship.vue'
import { detailEnterprise, getRaceArea, submitEnterprise } from '@/api/cyds';
import { useStore } from 'vuex';
import { ElMessage, ElMessageBox } from 'element-plus'
const store = useStore();
const blankimg = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQYV2NgAAIAAAUAAarVyFEAAAAASUVORK5CYII='
const imgSrc = ref(blankimg)
const value = ref([])
const activeName = ref('first')
const dialogVisible = ref(false)
const dialogVisible2 = ref(false)
const dialogVisible3 = ref(false)
const dialogVisible4 = ref(false)
const canvasSign2 = ref()
const showPlanEntrepreneurship = ref(false)
const showPlanEntrepreneurship2 = ref(false)
//所属单位
const zoneoptions = ref([])
const dialogTableVisible = ref(false)
// 区下拉框
const districtOptionDistrict = ref([
  {
    districtName: '奎文',
    districtCode: 'kuiwen'
  }
])
const PlanEntrepreneurshipId = ref('')
const PlanEntrepreneurshipId2 = ref('')
const chuangyejihua = (enterpriseType) => {
  detailEnterprise(
    {
      enterpriseType,
      stuId: store.state.stuinfo.id,
    }
  ).then(res => {
    console.log(res)
    if (res.data.id) {
      if (enterpriseType == 1) {
        PlanEntrepreneurshipId.value = res.data.id
        showPlanEntrepreneurship.value = true
      } else if (enterpriseType == 2) {
        PlanEntrepreneurshipId2.value = res.data.id
        showPlanEntrepreneurship2.value = true
      }
    } else {
      ElMessage.warning(
        `请先报名`
      )
    }
  })
}
const fileList = ref([
  // {
  //   name: 'food2.jpeg',
  //   url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100',
  // },
])

// 使用slot的clear方法
const clearWithSlotHandle = (clear) => {
  clear && clear() // 清空画布
  imgSrc.value = blankimg // 清空图片
}
// slot中save方法回调
const saveCallback = (imgBase64) => {
  imgSrc.value = imgBase64 || blankimg
  console.log(imgSrc.value)
}

const handleClick = (tab, event) => {
  console.log(tab, event)
}
const handleChange = (value) => {
  console.log(value)
}
const handleExceed = (files, uploadFiles) => {
  ElMessage.warning(
    `超出限制`
  )
}
const getNowFormatDate = () => {
  // 获取当月时间 yyyy-MM-dd
  let date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let strDate = date.getDate();
  if (month >= 1 && month <= 9) {
    month = '0' + month;
  }
  if (strDate >= 1 && strDate <= 9) {
    strDate = '0' + strDate;
  }
  return year + '-' + month + '-' + strDate;
}
const getUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    return (c === 'x' ? (Math.random() * 16) | 0 : 'r&0x3' | '0x8').toString(16);
  });
}
const ReporthttpRequest = (e) => {
  let file = e.file // 文件信息
  let flname = file.name
  let date = getNowFormatDate();
  let fileNames = getUUID() + file.name.substring(file.name.lastIndexOf('.'));
  let filePath = `upload/${date}/${fileNames}`;
  if (file.size >= 1024 * 1024 * 20) {
    this.$message({
      type: "error",
      message: '文件过大'
    });
  } else {
    client().put(filePath, file).then(ret => {
      if (ret.name) {
        // 获得图片地址
        // then.path = ret.name;
        // option.onSuccess();
        console.log(fileList)
        fileList.value.push(
          {
            name: flname,
            url: ret.url,
          }
        )
        return true
      }
    });
  }
}
const handleRemove = (e) => {
  fileList.value.forEach((item, index) => {
    if (item.url == e.url) {
      fileList.value.splice(index, 1)

    }
  })
}

const addDomain = () => {

}
//签名
const autograph = () => {
  dialogTableVisible.value = true
}
const onSubmit = () => {
  console.log('submit!')
}
</script>
<script>
export default {
  data () {
    return {
      form: {
        name: '',
        resource: '',
        type: [],
        desc: `声明：本人已详细阅读本次大赛的相关文件，并保证遵守有关规定。
申报人同意无偿提供申报项目介绍，由主办单位公开推介。申报人承诺提供的技术文件和资料真实、可靠，作品的知识产权权利归属明确无争议；未剽窃他人成果；未侵犯他人的知识产权；提供的经济效益及社会效益数据及证明客观、真实。若发生与上述承诺相违背的情形，由申报人自行承担全部法律责任。`
      }
    }
  }
}
</script>

<style>
.el-row {
  margin-bottom: 20px;
}
.el-col {
  border-radius: 4px;
}
</style>