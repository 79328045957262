<template>
  <el-form :model="form" label-width="160px" :disabled='form.isConfirm==1'>
    <!-- <el-form-item label="所属单位">
      <el-select v-model="form.zoneo" placeholder="请选择所属单位">
        <el-option v-for="item in zoneoptions" :key="item.livingDistrictCode" :label="item.districtName"
          :value="item.districtCode">
        </el-option>
      </el-select>
    </el-form-item> -->
    <el-form-item label="已提交" v-if="form.isConfirm==1">
    </el-form-item>
    <el-form-item label="申报人姓名">
      <el-input v-model="form.personName" disabled />
    </el-form-item>
    <el-form-item label="性别">
      <el-radio-group v-model="form.sex" disabled>
        <el-radio :label="1" value='男'>男</el-radio>
        <el-radio :label="2">女</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="出生年月">
      <el-date-picker v-model="form.birthDate" type="date" disabled placeholder="请选择日期" />
    </el-form-item>
    <el-form-item label="身份证号">
      <el-input v-model="form.idNumber" disabled />
    </el-form-item>
    <el-form-item label="免冠证件照">
      <!-- <el-input v-model="form.idNumber" disabled /> -->
      <SingleUploadFace @dataChange="dataChange" v-bind:stuType="1" v-bind:path="form.personalPhoto"
        v-bind:imageType="'1'">
      </SingleUploadFace>
      <!-- <div>
        身份证正面
        <SingleUploadFace @dataChange="dataChange" v-bind:stuType="1" v-bind:path="form.personalPhoto"
          v-bind:imageType="'1'">
        </SingleUploadFace>
        身份证反面
        <SingleUploadFace @dataChange="dataChange" v-bind:stuType="1" v-bind:path="form.personalPhoto"
          v-bind:imageType="'1'">
        </SingleUploadFace>
        证件照
        <SingleUploadFace @dataChange="dataChange" v-bind:stuType="1" v-bind:path="form.personalPhoto"
          v-bind:imageType="'1'">
        </SingleUploadFace>
      </div> -->
    </el-form-item>
    <el-form-item label="联系电话">
      <el-input v-model="form.mobilePhone" />
    </el-form-item>
    <el-form-item label="所属单位赛区">
      <el-cascader :options="RaceArea" style='width:100%' v-model="form.areaShow"
        :props='{label:"name",value:"tenantId"}'>
      </el-cascader>
    </el-form-item>
    <el-form-item label="政治面貌">
      <el-select v-model="form.politicalStatus" value-key='dictValue' @change='change'>

        <el-option v-for="item in politicsTypeOptions" :key="item.dictKey" :label="item.dictValue"
          :value="item.dictKey" />
      </el-select>
    </el-form-item>
    <el-form-item label="学历">
      <el-select v-model="form.education">

        <el-option v-for="item in educationOptions" :key="item.value" :label="item.dictValue" :value="item.dictKey" />
      </el-select>
    </el-form-item>
    <el-form-item label="专业">
      <el-input v-model="form.major" />
    </el-form-item>
    <el-form-item label="E-mail">
      <el-input v-model="form.email" />
    </el-form-item>

    <!-- <el-form-item label="参赛类别" v-if="!props.dsc">
      <el-radio-group v-model="form.raceType">
        <el-radio label="创业企业" />
        <el-radio label="创新创意项目" />
      </el-radio-group>
    </el-form-item> -->

    <!-- <el-form-item label="推荐形式" v-if="!props.dsc">
      <el-radio-group v-model="form.recommendType">
        <el-radio label="县市区推荐" />
        <el-radio label="高校直推" />
      </el-radio-group>
    </el-form-item> -->
    <!-- <el-form-item label="推荐报告" v-if="!props.dsc">
      <el-upload class="upload-demo" accept=".jpg, .jpeg, .png, .gif" action="" :on-remove="handleRemove"
        :file-list='fileList' limit='1' :http-request="ReporthttpRequest" :on-exceed='handleExceed'>
        <el-button type="primary">点击上传</el-button>
        <template #tip>
          <div class="el-upload__tip">
            加盖推荐单位公章的扫描版
          </div>
        </template>
      </el-upload>
    </el-form-item> -->
    <el-form-item label="公司/团队名称">
      <el-input v-model="form.teamName" />
    </el-form-item>
    <el-form-item label="注册时间">
      <el-date-picker v-model="form.teamDate" type="date" value-format='YYYY-MM-DD' placeholder="请选择日期" />
    </el-form-item>
    <el-form-item label="注册资本">
      <el-input v-model.number="form.teamMoney"> <template #append>万元</template>
      </el-input>
    </el-form-item>
    <el-form-item label="实收资本">
      <el-input v-model="form.teamMoneyReal"><template #append>万元</template></el-input>
    </el-form-item>
    <el-form-item label="法定代表人">
      <el-input v-model="form.legalPerson" />
    </el-form-item>
    <el-form-item label="统一社会信用代码">
      <el-input v-model="form.licenseNumber" />
    </el-form-item>
    <el-form-item label="注册地址">
      <el-input v-model="form.teamAddr" />
    </el-form-item>
    <el-form-item label="企业/项目人数">
      <el-input v-model="form.teamStaff" />
    </el-form-item>
    <el-form-item label="公司网址">
      <el-input v-model="form.teamWebsite" />
    </el-form-item>
    <el-form-item label="邮政编码">
      <el-input v-model="form.postalCode" />
    </el-form-item>
    <el-form-item label="申报人主要工作经历">
      <el-input type="textarea" :autosize="{ minRows: 2, }" v-model="form.workExperience" />
    </el-form-item>
    <el-form-item label="项目名称">
      <el-input v-model="form.projectTitle" />
    </el-form-item>
    <el-form-item label="产业领域">
      <el-input type="textarea" :autosize="{ minRows: 2, }" v-model="form.projectField" />
    </el-form-item>
    <el-form-item label="项目简介">
      <el-input type="textarea" :autosize="{ minRows: 2, }" v-model="form.projectBrief" />
    </el-form-item>
    <el-form-item label="项目现状">
      <el-input :autosize="{ minRows: 2, }" type="textarea" v-model="form.projectSituation" />
    </el-form-item>
    <!-- <el-form-item label="企业（项目）主要股东管理人员">
      <el-form label-width="100px" v-for="index in 2" :key='index'>
        <el-row>
          <el-col :span="8">
            <el-form-item label="姓名">
              <el-input v-model="form.name" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="性别">
              <el-radio-group v-model="form.resource">
                <el-radio label="男" />
                <el-radio label="女" />
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="文化程度">
              <el-input v-model="form.name" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="专业">
              <el-input v-model="form.name" />
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="电话">
              <el-input v-model="form.name" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-form-item> -->
    <el-form-item label="企业获得荣誉奖项">
      <el-input v-model="form.projectHonor" :autosize="{ minRows: 2, }" type="textarea" />
    </el-form-item>
    <el-form-item label="企业核心技术">
      <el-checkbox-group v-model="form.projectSkillShow">
        <el-checkbox label="发明专利" name="type" />
        <el-checkbox label="实用新型专利" name="type" />
        <el-checkbox label="专有技术" name="type" />
        <el-checkbox label="软件著作权" name="type" />
        <el-checkbox label="创新商业模式" name="type" />
        <el-row>
          <el-col :span="4">
            <el-checkbox label="其他请说明" name="type" />
          </el-col>
          <el-col :span="20">
            <el-input v-model="form.projectSkillOther" />
          </el-col>
        </el-row>
      </el-checkbox-group>
    </el-form-item>
    <el-form-item label="声明">
      <el-input v-model="form.desc" autosize type="textarea" disabled />
      <el-row>
        <el-col :span="6">
          <el-button class="mt-2" @click="autograph(domain)" style="margin-top:20px;">签名</el-button>

        </el-col>
        <el-col :span="6">
          <img v-if="form.signature" :src="'https://qjck.oss-cn-shanghai.aliyuncs.com/'+form.signature" alt="签名" />

          <img v-else w-full :src="imgSrc" alt="签名" />
        </el-col>
      </el-row>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit">暂存</el-button>

      <el-button type="primary" @click="onSubmit2">提交</el-button>
    </el-form-item>
  </el-form>
  <el-dialog v-model="dialogTableVisible" title="签名" width="1000px">
    <CanvasSign ref="canvasSign2" :height="400" :width="800" :line-width="10">
      <template v-slot="{ save, clear }">
        <el-button @click="() => save(saveCallback)">保存</el-button>
        <el-button @click="() => clearWithSlotHandle(clear)">清除</el-button>
      </template>
    </CanvasSign>
  </el-dialog>
</template>

<script setup>
import { nationality, educationOption, politicsTypeOption } from "@/api/dict/dictOption"
import { ref, reactive } from 'vue'
import SingleUploadFace from "@/components/single-upload/mainFace";
import { client, objectToBuffer } from '@/utils/ali-oss.js'  // 引入oss.js文件
import CanvasSign, { ICanvasSign } from 'vue-canvas-sign'
import { detailEnterprise, getRaceArea, submitEnterprise } from '@/api/cyds';
import { useStore } from 'vuex';
import { ElMessage, ElMessageBox } from 'element-plus'
const store = useStore();
const blankimg = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQYV2NgAAIAAAUAAarVyFEAAAAASUVORK5CYII='
const imgSrc = ref(blankimg)
const value = ref([])
const activeName = ref('first')
const canvasSign2 = ref()
const props = defineProps({
  dsc: {
    type: String,
    default: ''
  }
})

const dataChange = (params, imageType, data) => {
  console.log(params)

  if (imageType === '1') {
    addData.personalPhoto = params;
  } else if (imageType === '2') {
    addData.identityUpPhoto = params;
  } else if (imageType === '3') {
    addData.identityDownPhoto = params;
  } else if (imageType === '4') {
    addData.diplomaDownPhoto = params;
  } else if (imageType === '5') {
    addData.diplomaUpPhoto = params;
  } else if (imageType === '6') {
    addData.positionUpPhoto = params;
  } else if (imageType === '7') {
    addData.positionDownPhoto = params;
  } else if (imageType === '8') {
    addData.entrepreneurshipCertificate = params;
  }
}
console.log(props)
const form = reactive({
  name: '',
  resource: '',
  birthDate: "",
  type: [],
  projectSkillShow: [],
  desc: `声明：本人已详细阅读本次大赛的相关文件，并保证遵守有关规定。
申报人同意无偿提供申报项目介绍，由主办单位公开推介。申报人承诺提供的技术文件和资料真实、可靠，作品的知识产权权利归属明确无争议；未剽窃他人成果；未侵犯他人的知识产权；提供的经济效益及社会效益数据及证明客观、真实。若发生与上述承诺相违背的情形，由申报人自行承担全部法律责任。`
})
const RaceArea = ref([])
//所属单位
const zoneoptions = ref([])
const dialogTableVisible = ref(false)

// 区下拉框
const districtOptionDistrict = ref([
  {
    districtName: '奎文',
    districtCode: 'kuiwen'
  }
])
const nationalitys = ref([])//民族

console.log(store.state.stuinfo.id)
const fileList = ref([
  // {
  //   name: 'food2.jpeg',
  //   url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100',
  // },
])
// 使用slot的clear方法
const clearWithSlotHandle = (clear) => {
  clear && clear() // 清空画布
  imgSrc.value = blankimg // 清空图片
}

// slot中save方法回调
const saveCallback = (imgBase64) => {
  imgSrc.value = imgBase64 || blankimg
  uoloadqianm(imgBase64)
  console.log(imgSrc.value)
  dialogTableVisible.value = false
}
const handleClick = (tab, event) => {
  console.log(tab, event)
}
const handleChange = (value) => {
  console.log(value)
}
const change = (value) => {
  console.log(value)
  console.log(form.politicalStatus)
  console.log(politicsTypeOptions)
}
//信息

//赛区
getRaceArea({}).then(res => {
  console.log(res)
  res.data.forEach(item => {
    item.children.forEach(element => {
      element.children = ''
    });
  })
  RaceArea.value = res.data
})
nationality().then(res => {
  // console.log(res)
})
//政治面貌
const politicsTypeOptions = ref([])

politicsTypeOption().then(res => {
  console.log(res)
  politicsTypeOptions.value = res.data
  detailEnterprise({
    enterpriseType: props.dsc ? 2 : 1,
    stuId: store.state.stuinfo.id,
  }).then(res => {
    // console.log(res)
    if (res.data) {
      if (res.data.signature) {
        setTimeout(() => {

        }, 1000)
      }

      // imgSrc.value = 'https://qjck.oss-cn-shanghai.aliyuncs.com/' + res.data.signature
      Object.assign(form, res.data)

    }
  })
})
//学历
const educationOptions = ref([])
educationOption().then(res => {
  console.log(res)
  educationOptions.value = res.data
})
const handleExceed = (files, uploadFiles) => {
  console.log(123)
  ElMessage.warning(
    `超出限制,请先删除当前文件再上传`
  )
}
const getNowFormatDate = () => {
  // 获取当月时间 yyyy-MM-dd
  let date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let strDate = date.getDate();
  if (month >= 1 && month <= 9) {
    month = '0' + month;
  }
  if (strDate >= 1 && strDate <= 9) {
    strDate = '0' + strDate;
  }
  return year + '-' + month + '-' + strDate;
}
const getUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    return (c === 'x' ? (Math.random() * 16) | 0 : 'r&0x3' | '0x8').toString(16);
  });
}
const uoloadqianm = (file) => {
  let fileNames = getUUID() + '.png';
  let date = getNowFormatDate();
  let filePath = `upload/${date}/${fileNames}`;
  let buffer = objectToBuffer(file);
  client().put(filePath, dataURLtoFile(file, fileNames)).then(ret => {
    if (ret.name) {
      form.signature = ret.name
    }
  });
}
const dataURLtoFile = function (dataurl, filename) {
  var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}
const ReporthttpRequest = (e) => {
  let file = e.file // 文件信息
  let flname = file.name
  let date = getNowFormatDate();
  let fileNames = getUUID() + file.name.substring(file.name.lastIndexOf('.'));
  let filePath = `upload/${date}/${fileNames}`;
  if (file.size >= 1024 * 1024 * 20) {
    his.$message({
      type: "error",
      message: '文件过大'
    });
  } else {
    client().put(filePath, file).then(ret => {
      if (ret.name) {
        // 获得图片地址
        // then.path = ret.name;
        // option.onSuccess();
        console.log(fileList)
        form.recommendFile = ret.url
        // fileList.value.push(
        //   {
        //     name: flname,
        //     url: ret.url,
        //   }
        // )
        e.onSuccess();
        return true
      }
    });
  }
}
const handleRemove = (e) => {
  fileList.value.forEach((item, index) => {
    if (item.url == e.url) {
      fileList.value.splice(index, 1)

    }
  })
}

const addDomain = () => {

}
//签名
const autograph = () => {
  dialogTableVisible.value = true
}
const onSubmit = () => {
  console.log('submit!')
  submitEnterprise(form).then(res => {
    console.log(res)
    if (res.code == 200) {
      politicsTypeOption().then(res => {
        console.log(res)
        politicsTypeOptions.value = res.data
        detailEnterprise({
          enterpriseType: props.dsc ? 2 : 1,
          stuId: store.state.stuinfo.id,
        }).then(res => {
          // console.log(res)
          if (res.data) {
            // imgSrc.value = 'https://qjck.oss-cn-shanghai.aliyuncs.com/' + res.data.signature
            Object.assign(form, res.data)

          }
        })
      })
      ElMessage({
        message: '暂存成功.',
        type: 'success',
      })
    }
  })
}
const onSubmit2 = () => {
  console.log('submit!')

  ElMessageBox.confirm(
    '提交后不能修改是否提交?',
    '提示',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }
  )
    .then(() => {
      form.isConfirm = 1
      submitEnterprise(form).then(res => {
        console.log(res)
        if (res.code == 200) {
          ElMessage({
            message: '提交成功.',
            type: 'success',
          })
        }
      })
    })
    .catch(() => {

    })
}

</script>

<style>
</style>