<template>
  <el-row :gutter="24" justify="center" style="margin-top: 30px;margin-bottom: 10px;text-align: left">

    <el-col :span="6">
      <el-button effect="dark" @click='showtan(1,"执行摘要")'>
        执行摘要
      </el-button>
    </el-col>
    <el-col :span="6">
      <el-button effect="dark" @click='showtan(2,"市场分析")'>
        市场分析
      </el-button>
    </el-col>
    <el-col :span="6">
      <el-button effect="dark" @click='showtan(3,"营销策略")'>
        营销策略
      </el-button>
    </el-col>
    <el-col :span="6">
      <el-button effect="dark" @click='showtan(4,"管理团队")'>
        管理团队
      </el-button>
    </el-col>
  </el-row>
  <el-row :gutter="24" justify="center" style="margin-top: 30px;margin-bottom: 10px;text-align: left">

    <el-col :span="6">
      <el-button effect="dark" @click='showtan(5,"启动资金来源(单位：万元)")'>
        启动资金来源
      </el-button>
    </el-col>
    <el-col :span="6">
      <el-button effect="dark" @click='showtan(6,"最近年度利润(单位：万元)")'>
        最近年度利润
      </el-button>
    </el-col>
    <el-col :span="6">
      <el-button effect="dark" @click='showtan(7,"利润预测(单位：万元)")'>
        利润预测
      </el-button>
    </el-col>
    <el-col :span="6">
      <el-button effect="dark" @click='showtan(8,"融资需求")'>
        融资需求
      </el-button>
    </el-col>
  </el-row>
  <el-row :gutter="24" justify="center" style="margin-top: 30px;margin-bottom: 10px;text-align: left">

    <el-col :span="6">
      <el-button effect="dark" @click='showtan(9,"风险分析与对策")'>
        风险分析与对策
      </el-button>
    </el-col>
    <el-col :span="6">
      <el-button effect="dark" @click='showtan(10,"企业愿景")'>
        企业愿景
      </el-button>
    </el-col>
    <el-col :span="6">

    </el-col>
    <el-col :span="6">

    </el-col>
  </el-row>
  <el-row :gutter="24" justify="center" style="margin-top: 30px;margin-bottom: 10px;text-align: center">

    <el-col :span="10">
    </el-col>
    <el-col :span="4">
      <el-button type="info" v-if='datas.isPlanConfirm==1'>已提交</el-button>

      <el-button type="primary" v-else @click="onSubmit2">提交</el-button>
    </el-col>
    <el-col :span="10">
    </el-col>
  </el-row>

  <el-dialog v-model="dialogTableVisible2" destroy-on-close :title="lie.names" width="1000px">
    <Execute v-if="lie.type == 1" :PlanEntrepreneurshipId='props.PlanEntrepreneurshipId'
      :isPlanConfirm='datas.isPlanConfirm'></Execute>
    <Market v-if="lie.type == 2" :PlanEntrepreneurshipId='props.PlanEntrepreneurshipId'
      :isPlanConfirm='datas.isPlanConfirm'></Market>
    <Management v-if="lie.type == 3" :PlanEntrepreneurshipId='props.PlanEntrepreneurshipId'
      :isPlanConfirm='datas.isPlanConfirm'></Management>
    <Team v-if="lie.type == 4" :PlanEntrepreneurshipId='props.PlanEntrepreneurshipId'
      :isPlanConfirm='datas.isPlanConfirm'></Team>
    <MoneySource v-if="lie.type == 5" :PlanEntrepreneurshipId='props.PlanEntrepreneurshipId'
      :isPlanConfirm='datas.isPlanConfirm'></MoneySource>
    <MoneyHistory v-if="lie.type == 6" :PlanEntrepreneurshipId='props.PlanEntrepreneurshipId'
      :isPlanConfirm='datas.isPlanConfirm'></MoneyHistory>
    <MoneyFuture v-if="lie.type == 7" :PlanEntrepreneurshipId='props.PlanEntrepreneurshipId'
      :isPlanConfirm='datas.isPlanConfirm'></MoneyFuture>
    <PlanFinancingDetail v-if="lie.type == 8" :PlanEntrepreneurshipId='props.PlanEntrepreneurshipId'
      :isPlanConfirm='datas.isPlanConfirm'>
    </PlanFinancingDetail>
    <Risk v-if="lie.type == 9" :PlanEntrepreneurshipId='props.PlanEntrepreneurshipId'
      :isPlanConfirm='datas.isPlanConfirm'></Risk>
    <Vision v-if="lie.type == 10" :PlanEntrepreneurshipId='props.PlanEntrepreneurshipId'
      :isPlanConfirm='datas.isPlanConfirm'></Vision>
  </el-dialog>
</template>

<script setup>
import { ref, reactive, getCurrentInstance, defineProps } from 'vue'
import Execute from './PlanEntrepreneurship/Execute.vue'
import Market from './PlanEntrepreneurship/Market.vue'
import Management from './PlanEntrepreneurship/Management.vue'
import Team from './PlanEntrepreneurship/Team.vue'
import MoneySource from './PlanEntrepreneurship/MoneySource.vue'
import MoneyHistory from './PlanEntrepreneurship/MoneyHistory.vue'
import MoneyFuture from './PlanEntrepreneurship/MoneyFuture.vue'
import PlanFinancingDetail from './PlanEntrepreneurship/PlanFinancingDetail.vue'
import Risk from './PlanEntrepreneurship/Risk.vue'
import Vision from './PlanEntrepreneurship/Vision.vue'
import { detailEnterprise, confirmPlan } from '@/api/cyds';
import { useStore } from 'vuex';
import { ElMessage, ElMessageBox } from 'element-plus'
const store = useStore();
const dialogTableVisible = ref(true)
const dialogTableVisible2 = ref(false)
const props = defineProps({
  PlanEntrepreneurshipId: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: ''
  }
})
const lie = reactive({})
const datas = ref({})
console.log(props.PlanEntrepreneurshipId,)
const showtan = (type, names) => {
  lie.type = type
  lie.names = names
  dialogTableVisible2.value = true
}
dialogTableVisible.value = true
detailEnterprise({
  enterpriseType: props.type,
  stuId: store.state.stuinfo.id,
}).then(res => {
  console.log(res)
  datas.value = res.data
})
const onSubmit2 = () => {
  ElMessageBox.confirm(
    '提交后不能修改是否提交?',
    '提示',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }
  )
    .then(() => {
      confirmPlan({
        raceId: props.PlanEntrepreneurshipId
      }).then(res => {
        console.log(res)
        if (res.code == 200) {
          detailEnterprise({
            enterpriseType: props.type,
            stuId: store.state.stuinfo.id,
          }).then(res => {
            console.log(res)
            datas.value = res.data
          })
          ElMessage({
            message: '提交成功.',
            type: 'success',
          })
        }
      })
    })
    .catch(() => {

    })
}
</script>

<style>
</style>