import request from '@/utils/cydsrequst';
/*
 *  模块：新建班级
 * */
// 培训类型
export const dictionary = () => {
  return request({
    url: '/api/blade-system/dict/dictionary?code=training_type',
    method: 'get'
  })
}

//   培训方式
export const dictionaryWay = () => {
  return request({
    url: '/api/blade-system/dict/dictionary?code=training_method',
    method: 'get'
  })
}

/*
*  模块：学员管理
* */
//   婚姻
export const marriage = () => {
  return request({
    url: '/api/blade-system/dict/dictionary?code=marriage',
    method: 'get'
  })
}

//   民族
export const nationality = () => {
  return request({
    url: '/api/blade-system/dict/dictionary?code=nationality',
    method: 'get'
  })
}

// 证件类型
export const typeOfCertificate = () => {
  return request({
    url: '/api/blade-system/dict/dictionary?code=type_of_certificate',
    method: 'get'
  })
}

// 性别下拉框
export const sexOption = () => {
  return request({
    url: '/api/blade-system/dict/dictionary?code=sex',
    method: 'get'
  })
}
// 学员类型下拉框
export const stuTypeOption = () => {
  return request({
    url: '/api/blade-system/dict/dictionary?code=stu_type',
    method: 'get'
  })
}
// 审核状态
export const reviewStatusOption = () => {
  return request({
    url: '/api/blade-system/dict/dictionary?code=review_status',
    method: 'get'
  })
}

// 来源方式
export const sourceWayOption = () => {
  return request({
    url: '/api/blade-system/dict/dictionary?code=source_way',
    method: 'get'
  })
}

// 政治面貌
export const politicsTypeOption = () => {
  return request({
    url: '/api/blade-system/dict/dictionary?code=political_status',
    method: 'get'
  })
}

// 户口性质下拉框
export const accountNatureOption = () => {
  return request({
    url: '/api/blade-system/dict/dictionary?code=account_type',
    method: 'get'
  })
}
// 国籍下拉框
export const nationOption = () => {
  return request({
    url: '/api/blade-system/dict/dictionary?code=nation',
    method: 'get'
  })
}

// 是否干部下拉框
export const leaderOption = () => {
  return request({
    url: '/api/blade-system/dict/dictionary?code=yes_no',
    method: 'get'
  })
}

// 健康状况
export const health = () => {
  return request({
    url: '/api/blade-system/dict/dictionary?code=health',
    method: 'get'
  })
}

// 最高学历下拉框
export const educationOption = () => {
  return request({
    url: '/api/blade-system/dict/dictionary?code=education',
    method: 'get'
  })
}

// 行政职务
export const administrativePost = () => {
  return request({
    url: '/api/blade-system/dict/dictionary?code=administrative_post',
    method: 'get'
  })
}
// 国家职业资格等级下拉框
export const stateOccupation = () => {
  return request({
    url: '/api/blade-system/dict/dictionary?code=state_occupation',
    method: 'get'
  })
}
// 专业技术职务等级下拉框
export const majorTechnology = () => {
  return request({
    url: '/api/blade-system/dict/dictionary?code=major_technology',
    method: 'get'
  })
}

// 与紧急联系人的关系下拉框
export const urgencyRelation = () => {
  return request({
    url: '/api/blade-system/dict/dictionary?code=urgency_relation',
    method: 'get'
  })
}

// 个人身份下拉框
export const personageIdentity = () => {
  return request({
    url: '/api/blade-system/dict/dictionary?code=personage_identity',
    method: 'get'
  })
}

// 课程类型下拉框
export const codeTree = () => {
  return request({
    url: '/api/blade-system/dict/codeTree?code=curriculum',
    method: 'get'
  })
}

// 计划书状态
export const rejectStatus = () => {
  return request({
    url: '/api/blade-system/dict/codeTree?code=reject_status',
    method: 'get'
  })
}

// 编号下拉框
export const selectList = () => {
  return request({
    url: '/api/blade-policy/stucertificate/selectList?type=1',
    method: 'get'
  })
}

// 模板类型
export const templateType = () => {
  return request({
    url: '/api/blade-system/dict/codeTree?code=template_type',
    method: 'get'
  })
}

// 业务类型
export const businessType = () => {
  return request({
    url: '/api/blade-system/dict/codeTree?code=business_type',
    method: 'get'
  })
}

// 业务类型--济南使用
export const businessTypeJN = () => {
  return request({
    url: '/api/blade-system/dict/tree?code=business_type',
    method: 'get'
  })
}


// 计划书评审状态
export const planEcpertStatus = () => {
  return request({
    url: '/api/blade-system/dict/codeTree?code=plan_review_status',
    method: 'get'
  })
}

// 备案状态
export const recordStatus = () => {
  return request({
    url: '/api/blade-system/dict/codeTree?code=record_status',
    method: 'get'
  })
}

// 庄家与班级状态
export const classExpertStatus = () => {
  return request({
    url: '/api/blade-system/dict/codeTree?code=class_expert_status',
    method: 'get'
  })
}

// 计划书评审管理 班级评审管理
export const classReviewStatus = () => {
  return request({
    url: '/api/blade-system/dict/codeTree?code=class_review_status',
    method: 'get'
  })
}

// 业务类型
export const businessTypeSelect = () => {
  return request({
    url: '/api/blade-policy/class/businessTypeSelect',
    method: 'get'
  })
}

// 机构类型下拉框
export const getTenantType = () => {
  return request({
    url: '/api/blade-system/dict/dictionary?code=tenant_type',
    method: 'get'
  })
}

// 官网课程分类
export const curriculumTrain = () => {
  return request({
    url: '/api/blade-system/dict/codeTree?code=curriculum_train',
    method: 'get'
  })
}
