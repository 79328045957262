<template>
  <el-form :model="form" label-width="160px" :disabled='isPlanConfirm'>
    <el-form-item label="企业愿景">
      <el-input v-model="form.enterpriseVision" :autosize="{ minRows: 3, }" type="textarea"
        placeholder="企业及其内部全体员工共同追求的企业发展愿望和长远目标的情景式描述，对企业发展具有导向功能，对员工具有激励与凝聚作用。" />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit">暂存</el-button>
    </el-form-item>
  </el-form>
</template>

<script setup>
import { ref, reactive, getCurrentInstance } from 'vue'
import { detailVision, submitVision } from '@/api/cyds';
import { ElMessage, ElMessageBox } from 'element-plus'
const props = defineProps({
  PlanEntrepreneurshipId: {
    type: String,
    default: ''
  }, isPlanConfirm: {
    type: String,
    default: ''
  },
})
const form = reactive({})
console.log(props)
detailVision({
  raceId: props.PlanEntrepreneurshipId
}).then(res => {
  console.log(res)
  if (res.data) {
    Object.assign(form, res.data)
  }
})
const onSubmit = () => {
  submitVision(form).then(res => {
    if (res.code == 200) {
      detailVision({
        raceId: props.PlanEntrepreneurshipId
      }).then(res => {
        console.log(res)
        if (res.data) {
          Object.assign(form, res.data)
        }
      })
      ElMessage({
        message: '暂存成功.',
        type: 'success',
      })
    } else {
      ElMessage({
        message: '参数错误.',
        type: 'error',
      })
    }
  })
}
</script>

<style>
</style>