<template>
  <el-form :model="form" label-width="0px" :disabled='isPlanConfirm'>
    <el-form-item label="">
      <el-table :data="list" border style="width: 100%; margin-top: 20px">
        <el-table-column prop="amount2" label="姓名">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <el-input v-model="scope.row.personName" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="amount2" label="性别">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <el-input v-model="scope.row.sex" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="amount3" label="年龄">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <el-input v-model="scope.row.age" type='number' />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="amount2" label="职务">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <el-input v-model="scope.row.position" type="text" placeholder="" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="amount2" label="文化程度">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <el-input v-model="scope.row.education" type="text" placeholder="" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="amount2" label="专业">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <el-input v-model="scope.row.major" type="text" placeholder="" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="amount2" label="主要工作经历">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <el-input v-model="scope.row.workExperience" type="text" placeholder="" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="amount2" label="优势专长">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <el-input v-model="scope.row.specialty" type="text" placeholder="" />
            </div>
          </template>
        </el-table-column>

      </el-table>
      <el-button class="mt-4" style="width: 100%" @click="onAddItem(1)">添加</el-button>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit">暂存</el-button>

    </el-form-item>
  </el-form>
</template>

<script setup>
import { ref, reactive, getCurrentInstance } from 'vue'
import { listTeam, submitTeam } from '@/api/cyds';
import { ElMessage, ElMessageBox } from 'element-plus'
const props = defineProps({
  PlanEntrepreneurshipId: {
    type: String,
    default: ''
  }, isPlanConfirm: {
    type: String,
    default: ''
  },
})
const form = reactive({})
console.log(props)
const list = reactive([
])
listTeam({
  raceId: props.PlanEntrepreneurshipId
}).then(res => {
  console.log(res)
  if (res.data) {
    Object.assign(list, res.data)
  }
})
const onSubmit = () => {
  submitTeam({ teamList: list }).then(res => {
    if (res.code == 200) {
      listTeam({
        raceId: props.PlanEntrepreneurshipId
      }).then(res => {
        console.log(res)
        if (res.data) {
          Object.assign(list, res.data)
        }
      })
      ElMessage({
        message: '暂存成功.',
        type: 'success',
      })
    } else {
      ElMessage({
        message: '参数错误.',
        type: 'error',
      })
    }
  })
}
const onAddItem = (type) => {
  list.push({
    personName: '',
    sex: '',
    age: '',
    position: '',
    education: '',
    major: '',
    workExperience: '',
    specialty: '',
    raceId: props.PlanEntrepreneurshipId
  })

}
</script>

<style>
</style>