<template>
  <el-form :model="form" label-width="160px" :disabled='isPlanConfirm'>
    <el-form-item label="当前是否有融资">
      <el-radio-group v-model="form.financingPlan">
        <el-radio label="1">是</el-radio>
        <el-radio label="0">否</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="计划融资方式">
      <el-radio-group v-model="form.financingType">
        <el-radio label="股权融资" />
        <el-radio label="债券融资" />
      </el-radio-group>
    </el-form-item>
    <el-form-item>
      <el-table :data="Financingdemandlist" border style="width: 100%; margin-top: 20px">
        <el-table-column prop="amount2" label="计划融资时间">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <el-date-picker v-model="scope.row.moneyDate" value-format='YYYY-MM-DD' type="date" placeholder="请选择日期" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="amount3" label="计划融资金额(万元)">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <el-input v-model="scope.row.moneyAmount" type='number' />
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-button class="mt-4" style="width: 100%" @click="onAddItem(1)">添加</el-button>
    </el-form-item>
    <el-form-item label="是否有融资经历 ">
      <el-radio-group v-model="form.financingExperience">
        <el-radio label="1">是</el-radio>
        <el-radio label="0">否</el-radio>
      </el-radio-group>
    </el-form-item>

    <el-form-item>
      <el-table :data="Financingdemandlist2" border style="width: 100%; margin-top: 20px">
        <el-table-column prop="amount2" label="投资人/机构">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <el-input v-model="scope.row.moneySource" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="amount3" label="融资金额(万元)">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <el-input v-model="scope.row.moneyAmount" type='number' />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="amount2" label="计划融资时间">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <el-date-picker v-model="scope.row.moneyDate" type="date" value-format='YYYY-MM-DD' placeholder="请选择日期" />
            </div>
          </template>
        </el-table-column>

      </el-table>
      <el-button class="mt-4" style="width: 100%" @click="onAddItem(2)">添加</el-button>
    </el-form-item>
    <el-form-item label="其它融资需求">
      <el-checkbox-group v-model="form.financingOtherShow">
        <el-checkbox label="科技咨询" name="type" />
        <el-checkbox label="融资担保" name="type" />
        <el-checkbox label="应收账款融资" name="type" />
        <el-checkbox label="技术/产权转让" name="type" />
        <el-checkbox label="金融租赁" name="type" />
        <el-checkbox label="股改和上市咨询" name="type" />
        <el-checkbox label="科技保险" name="type" />
        <el-checkbox label="小额贷款" name="type" />
        <el-checkbox label="信用贷款" name="type" />
        <el-checkbox label="股权质押贷款" name="type" />
        <el-checkbox label="知识产权质押贷款" name="type" />
        <el-checkbox label="中小企业集合债券发行" name="type" />
        <el-checkbox label="并购重组" name="type" />
        <el-checkbox label="资产管理" name="type" />
        <el-row>
          <el-col :span="4">
            <el-checkbox label="其他服务需求" name="type" />
          </el-col>
          <el-col :span="20">
            <el-input v-model="form.financingOtherDesc" />
          </el-col>
        </el-row>
        <el-checkbox label="无融资需求" name="type" />

      </el-checkbox-group>
    </el-form-item>

    <el-form-item>
      <el-button type="primary" @click="onSubmit">暂存</el-button>

    </el-form-item>
  </el-form>
</template>

<script setup>
import { ref, reactive, getCurrentInstance, computed } from 'vue'
import { detailFinancing, submitFinancing } from '@/api/cyds';
import { ElMessage, ElMessageBox } from 'element-plus'
const props = defineProps({
  PlanEntrepreneurshipId: {
    type: String,
    default: ''
  }, isPlanConfirm: {
    type: String,
    default: ''
  },
})
const form = reactive({
  financingType: [],
  projectSkillShow: [],
  financingOtherShow: [],
})
console.log(props)
detailFinancing({
  raceId: props.PlanEntrepreneurshipId
}).then(res => {
  console.log(res)
  if (res.data) {
    Object.assign(form, res.data)
    Object.assign(list, res.data.detailList)
  }
})
const onSubmit = () => {
  form.detailList = list
  submitFinancing(form).then(res => {
    if (res.code == 200) {
      detailFinancing({
        raceId: props.PlanEntrepreneurshipId
      }).then(res => {
        console.log(res)
        if (res.data) {
          Object.assign(form, res.data)
          Object.assign(list, res.data.detailList)
        }
      })
      ElMessage({
        message: '暂存成功.',
        type: 'success',
      })
    } else {
      ElMessage({
        message: '参数错误.',
        type: 'error',
      })
    }
  })
}
const list = reactive([
])
// const Financingdemandlist = reactive([
// ])
// const Financingdemandlist2 = reactive([
// ])
let Financingdemandlist = computed(() => {
  return list.filter(i => {
    return i.detailType == 1
  })
})
let Financingdemandlist2 = computed(() => {
  return list.filter(i => {
    return i.detailType == 2
  })
})
const onAddItem = (type) => {
  if (type == 1) {
    list.push({
      detailType: 1,
      moneyAmount: '',
      moneyDate: ''
    })
  } else {
    list.push({
      detailType: 2,
      moneyAmount: '',
      moneyDate: '',
      moneySource: ''
    })
  }

}
</script>

<style>
</style>