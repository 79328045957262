<template>
  <el-form :model="form" label-width="160px" :disabled='form.isConfirm==1'>
    <!-- <el-form-item label="所属单位">
      <el-select v-model="form.zoneo" placeholder="请选择所属单位">
        <el-option v-for="item in zoneoptions" :key="item.livingDistrictCode" :label="item.districtName"
          :value="item.districtCode">
        </el-option>
      </el-select>
    </el-form-item> -->
    <el-form-item label="已提交" v-if="form.isConfirm==1">
    </el-form-item>
    <el-form-item label="申报人姓名">
      <el-input v-model="form.personName" disabled />
    </el-form-item>
    <el-form-item label="性别">
      <el-radio-group v-model="form.sex" disabled>
        <el-radio :label="1">男</el-radio>
        <el-radio :label="2">女</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="出生年月">
      <el-date-picker v-model="form.birthDate" type="date" disabled placeholder="请选择日期" />
    </el-form-item>
    <el-form-item label="民族">
      <el-select v-model="form.nationality" value-key='dictValue' @change='change' disabled>

        <el-option v-for="item in nationalitys" :key="item.dictKey" :label="item.dictValue" :value="item.dictKey" />
      </el-select>
    </el-form-item>

    <el-form-item label="联系电话">
      <el-input v-model="form.mobilePhone" />
    </el-form-item>
    <el-form-item label="身份证号">
      <el-input v-model="form.idNumber" disabled />
    </el-form-item>
    <el-form-item label="所属单位赛区">
      <el-cascader :options="RaceArea" style='width:100%' v-model="form.areaShow"
        :props='{label:"name",value:"tenantId"}'>
      </el-cascader>
    </el-form-item>
    <el-form-item label="政治面貌">
      <el-select v-model="form.politicalStatus" value-key='dictValue' @change='change'>

        <el-option v-for="item in politicsTypeOptions" :key="item.dictKey" :label="item.dictValue"
          :value="item.dictKey" />
      </el-select>
    </el-form-item>
    <el-form-item label="文化程度">
      <el-select v-model="form.education">
        <el-option v-for="item in educationOptions" :key="item.value" :label="item.dictValue" :value="item.dictKey" />
      </el-select>
    </el-form-item>
    <el-form-item label="推荐单位">
      <el-input v-model="form.unitName" />
    </el-form-item>
    <el-form-item label="企业名称">
      <el-input v-model="form.teamName" />
    </el-form-item>
    <el-form-item label="成立时间">
      <el-date-picker v-model="form.teamDate" type="date" value-format='YYYY-MM-DD' placeholder="请选择日期" />
    </el-form-item>
    <el-form-item label="企业所在地">
      <el-input v-model="form.teamAddr" />
    </el-form-item>

    <el-form-item label="吸纳就业人数">
      <el-input v-model="form.teamStaff" />
    </el-form-item>
    <el-form-item label="资产总额（万元）">
      <el-input v-model="form.teamMoney" type='number' />
    </el-form-item>

    <el-form-item label="所属行业">
      <el-radio-group v-model="form.teamIndustry">
        <el-radio label="工业"></el-radio>
        <el-radio label="农业"></el-radio>
        <el-radio label="加工业"></el-radio>
        <el-radio label="服务业"></el-radio>
        <el-radio label="商业"></el-radio>
        <el-radio label="新兴科技"></el-radio>
        <el-radio label="电子商务"></el-radio>
        <el-radio label="其它类别"></el-radio>
      </el-radio-group>
    </el-form-item>

    <el-form-item label="创业事迹简介（字数不超过300字）">
      <el-input v-model="form.personalDeeds" :autosize="{ minRows: 3, }" type="textarea" />
    </el-form-item>

    <el-form-item label="推荐单位意见">
      <el-input v-model="form.unitOpinion" :autosize="{ minRows: 2, }" type="textarea" />
    </el-form-item>
    <el-form-item label="推荐单位意见日期">
      <el-date-picker v-model="form.unitDate" type="date" value-format='YYYY-MM-DD' placeholder="请选择日期" />
    </el-form-item>
    <el-form-item label="县市区（开发区）推荐意见">
      <el-input v-model="form.areaOpinion" :autosize="{ minRows: 3, }" type="textarea" />
    </el-form-item>
    <el-form-item label="县市区（开发区）推荐意见日期">
      <el-date-picker v-model="form.areaDate" type="date" value-format='YYYY-MM-DD' placeholder="请选择日期" />
    </el-form-item>
    <el-form-item label="评审委员会意见">
      <el-input v-model="form.committeeOpinion" :autosize="{ minRows: 3, }" type="textarea" />
    </el-form-item>
    <el-form-item label="评审委员会意见日期">
      <el-date-picker v-model="form.committeeDate" type="date" value-format='YYYY-MM-DD' placeholder="请选择日期" />
    </el-form-item>
    <el-form-item label="申请表盖章pdf版">
      <div>
        <el-upload class="upload-demo" accept=".pdf" action="" :on-remove="handleRemove" :file-list='fileList' limit='1'
          :http-request="ReporthttpRequest" :on-exceed='handleExceed'>
          <el-button type="primary">点击上传</el-button>
          <template #tip>
            <div class="el-upload__tip">
              推荐单位，县市区，评审委员会 加盖公章的pdf
            </div>
          </template>
        </el-upload>
      </div>
    </el-form-item>
    注：1.本表相关栏目填写不下，可另添附页。本表可复印；
    2.企业净资产、吸纳就业人数均截至到2021年12月31日数字，年销售收入、年纳税均指2021年数字。
    <el-form-item>
      <el-button type="primary" @click="onSubmit">暂存</el-button>

      <el-button type="primary" @click="onSubmit2">提交</el-button>
    </el-form-item>
  </el-form>
  <el-dialog v-model="dialogTableVisible" title="签名" width="1000px">
    <CanvasSign ref="canvasSign2" :height="400" :width="800" :line-width="10">
      <template v-slot="{ save, clear }">
        <el-button @click="() => save(saveCallback)">保存</el-button>
        <el-button @click="() => clearWithSlotHandle(clear)">清除</el-button>
      </template>
    </CanvasSign>
  </el-dialog>
</template>

<script setup>
import { nationality, educationOption, politicsTypeOption, } from "@/api/dict/dictOption"
import { ref, reactive } from 'vue'
import { client, getObjectUrl, objectToBuffer } from '@/utils/ali-oss.js'  // 引入oss.js文件
import CanvasSign, { ICanvasSign } from 'vue-canvas-sign'
import { detailFarmer, getRaceArea, submitEnterprise, submitFarmer } from '@/api/cyds';
import { useStore } from 'vuex';
import { ElMessage, ElMessageBox } from 'element-plus'
const store = useStore();
const blankimg = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQYV2NgAAIAAAUAAarVyFEAAAAASUVORK5CYII='
const imgSrc = ref(blankimg)
const value = ref([])
const activeName = ref('first')
const canvasSign2 = ref()
const props = defineProps({
  dsc: {
    type: String,
    default: ''
  }
})
console.log(props)
const form = reactive({
  name: '',
  resource: '',
  birthDate: "",
  type: [],
  projectSkillShow: [],
  desc: `本表中提供的所有信息是真实准确的，因信息不实所造成的后果本人愿承担一切责任。`
})
const RaceArea = ref([])
//所属单位
const zoneoptions = ref([])
const dialogTableVisible = ref(false)

// 区下拉框
const districtOptionDistrict = ref([
  {
    districtName: '奎文',
    districtCode: 'kuiwen'
  }
])
const nationalitys = ref([])//民族

console.log(store.state.stuinfo.id)
const fileList = ref([
  // {
  //   name: 'food2.jpeg',
  //   url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100',
  // },
])
// 使用slot的clear方法
const clearWithSlotHandle = (clear) => {
  clear && clear() // 清空画布
  imgSrc.value = blankimg // 清空图片
}

// slot中save方法回调
const saveCallback = (imgBase64) => {
  imgSrc.value = imgBase64 || blankimg
  uoloadqianm(imgBase64)
  console.log(imgSrc.value)
  dialogTableVisible.value = false
}
const handleClick = (tab, event) => {
  console.log(tab, event)
}
const handleChange = (value) => {
  console.log(value)
}
const change = (value) => {
  console.log(value)
  console.log(form.politicalStatus)
  console.log(politicsTypeOptions)
}
//信息

//赛区
getRaceArea({}).then(res => {
  console.log(res)
  res.data.forEach(item => {
    item.children.forEach(element => {
      element.children = ''
    });
  })
  RaceArea.value = res.data
})
nationality().then(res => {
  // console.log(res)
  nationalitys.value = res.data
})
//政治面貌
const politicsTypeOptions = ref([])
detailFarmer({
  stuId: store.state.stuinfo.id,
}).then(res => {
  // console.log(res)
  if (res.data) {
    Object.assign(form, res.data)
    if (res.data.personSignature) {
      setTimeout(() => {
        imgSrc.value = getObjectUrl(res.data.personSignature)
        console.log(imgSrc)
      }, 1000)
    }
  }
})
politicsTypeOption().then(res => {
  console.log(res)
  politicsTypeOptions.value = res.data

})

//学历
const educationOptions = ref([])
educationOption().then(res => {
  console.log(res)
  educationOptions.value = res.data
})
const handleExceed = (files, uploadFiles) => {
  console.log(123)
  ElMessage.warning(
    `超出限制,请先删除当前文件再上传`
  )
}
const getNowFormatDate = () => {
  // 获取当月时间 yyyy-MM-dd
  let date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let strDate = date.getDate();
  if (month >= 1 && month <= 9) {
    month = '0' + month;
  }
  if (strDate >= 1 && strDate <= 9) {
    strDate = '0' + strDate;
  }
  return year + '-' + month + '-' + strDate;
}
const getUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    return (c === 'x' ? (Math.random() * 16) | 0 : 'r&0x3' | '0x8').toString(16);
  });
}
const uoloadqianm = (file) => {
  let fileNames = getUUID() + '.png';
  let date = getNowFormatDate();
  let filePath = `upload/${date}/${fileNames}`;
  let buffer = objectToBuffer(file);
  client().put(filePath, dataURLtoFile(file, fileNames)).then(ret => {
    console.log(ret)
    if (ret.name) {
      form.personSignature = ret.name
    }
  });
}
const dataURLtoFile = function (dataurl, filename) {
  var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}
const ReporthttpRequest = (e) => {
  let file = e.file // 文件信息
  let flname = file.name
  let date = getNowFormatDate();
  let fileNames = getUUID() + file.name.substring(file.name.lastIndexOf('.'));
  let filePath = `upload/${date}/${fileNames}`;
  if (file.size >= 1024 * 1024 * 20) {
    his.$message({
      type: "error",
      message: '文件过大'
    });
  } else {
    client().put(filePath, file).then(ret => {
      if (ret.name) {
        // 获得图片地址
        // then.path = ret.name;
        // option.onSuccess();
        console.log(fileList)
        form.recommendFile = ret.url
        // fileList.value.push(
        //   {
        //     name: flname,
        //     url: ret.url,
        //   }
        // )
        e.onSuccess();
        return true
      }
    });
  }
}
const handleRemove = (e) => {
  fileList.value.forEach((item, index) => {
    if (item.url == e.url) {
      fileList.value.splice(index, 1)

    }
  })
}

const addDomain = () => {

}
//签名
const autograph = () => {
  dialogTableVisible.value = true
}
const onSubmit = () => {
  console.log('submit!')
  submitFarmer(form).then(res => {
    console.log(res)
    if (res.code == 200) {
      detailFarmer({
        stuId: store.state.stuinfo.id,
      }).then(res => {
        // console.log(res)
        if (res.data) {
          Object.assign(form, res.data)
          if (res.data.personSignature) {
            setTimeout(() => {
              imgSrc.value = getObjectUrl(res.data.personSignature)
              console.log(imgSrc)
            }, 1000)
          }
        }
      })
      ElMessage({
        message: '暂存成功.',
        type: 'success',
      })
    }
  })
}
const onSubmit2 = () => {
  console.log('submit!')

  ElMessageBox.confirm(
    '提交后不能修改是否提交?',
    '提示',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }
  )
    .then(() => {
      form.isConfirm = 1
      submitFarmer(form).then(res => {
        console.log(res)
        if (res.code == 200) {
          ElMessage({
            message: '提交成功.',
            type: 'success',
          })
        }
      })
    })
    .catch(() => {

    })
}

</script>

<style>
</style>