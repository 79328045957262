<template>
  <el-form :model="form" label-width="0px" :disabled='isPlanConfirm'>
    <el-form-item label="">
      <el-table :header-cell-style='headFirst' :data="profitList" :span-method="objectSpanMethod" border
        style="width: 100%; margin-top: 20px">
        <el-table-column label="项  目" align="center">
          <el-table-column prop="name2" label="项  目">
            <template #default="scope">
              <div style="display: flex; align-items: center">
                {{scope.row.name}}
              </div>
            </template>
          </el-table-column>
          <el-table-column>
            <template #default="scope">
              <div style="display: flex; align-items: center">
                {{scope.row.name2}}
              </div>
            </template>
          </el-table-column>

        </el-table-column>
        <el-table-column prop="amount1" label="19年期末余额">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <el-input v-model="form[scope.row.amount1]" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="amount2" label="20年期末余额">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <el-input v-model="form[scope.row.amount2]" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="amount3" label="21年期末余额">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <el-input v-model="form[scope.row.amount3]" />
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit">暂存</el-button>
    </el-form-item>
  </el-form>
</template>

<script setup>
import { ref, reactive, getCurrentInstance } from 'vue'
import { detailMoneyHistory, submitMoneyHistory } from '@/api/cyds';
import { ElMessage, ElMessageBox } from 'element-plus'
const props = defineProps({
  PlanEntrepreneurshipId: {
    type: String,
    default: ''
  }, isPlanConfirm: {
    type: String,
    default: ''
  },
})
const form = reactive({})
console.log(props)
detailMoneyHistory({
  raceId: props.PlanEntrepreneurshipId
}).then(res => {
  console.log(res)
  if (res.data) {
    Object.assign(form, res.data)
  }
})
const onSubmit = () => {
  submitMoneyHistory(form).then(res => {
    if (res.code == 200) {
      detailMoneyHistory({
        raceId: props.PlanEntrepreneurshipId
      }).then(res => {
        console.log(res)
        if (res.data) {
          Object.assign(form, res.data)
        }
      })
      ElMessage({
        message: '暂存成功.',
        type: 'success',
      })
    } else {
      ElMessage({
        message: '参数错误.',
        type: 'error',
      })
    }
  })
}
//合并表头
const headFirst = ({ row, colunm, rowIndex, columnIndex }) => {
  if (rowIndex === 1) {
    //这里为了是将第二列的表头隐藏，就形成了合并表头的效果
    return { display: 'none' }

  }
}
const profitList = reactive([
  {
    name: '一、主营业务收入',
    name2: '',
    amount1: 'profitMainOne',
    amount2: 'profitMainTwo',
    amount3: 'profitMainThree',
  }, {
    name: '加：其他收入',
    name2: '',
    amount1: 'profitOtherOne',
    amount2: 'profitOtherTwo',
    amount3: 'profitOtherThree',
  }, {
    name: '减：主营业务成本',
    name2: '生产/采购成本',
    amount1: 'costOne',
    amount2: 'costTwo',
    amount3: 'costThree',
  }, {
    name: '营业税金及附加(按5.5%计算)',
    name2: '',
    amount1: 'taxOne',
    amount2: 'taxTwo',
    amount3: 'taxThree',
  }, {
    name: '变动销售费用',
    name2: '销售提成',
    amount1: 'saleOne',
    amount2: 'saleThree',
    amount3: 'saleTwo',
  }, {
    name: '边际贡献率(%)=（主营业务收入-主营业务成本-营业税金-销售提成）/主营业务收入',
    name2: '',
    amount1: 'rateOne',
    amount2: 'rateTwo',
    amount3: 'rateThree',
  }, {
    name: '固定销售费用',
    name2: '宣传推广费',
    amount1: 'costExtensionOne',
    amount2: 'costExtensionTwo',
    amount3: 'costExtensionThree',
  }, {
    name: '管理费用',
    name2: '场地租金',
    amount1: 'costOfficeOne',
    amount2: 'costOfficeTwo',
    amount3: 'costOfficeThree',
  }, {
    name: '',
    name2: '员工薪酬',
    amount1: 'costStaffOne',
    amount2: 'costStaffTwo',
    amount3: 'costStaffThree',
  }, {
    name: '',
    name2: '办公用品及耗材',
    amount1: 'costGoodsOne',
    amount2: 'costGoodsTwo',
    amount3: 'costGoodsThree',
  }, {
    name: '',
    name2: '水、电、交通差旅费',
    amount1: 'costEnergyOne',
    amount2: 'costEnergyTwo',
    amount3: 'costEnergyThree',
  }, {
    name: '',
    name2: '固定资产折旧',
    amount1: 'costDepreciationOne',
    amount2: 'costDepreciationTwo',
    amount3: 'costDepreciationThree',
  }, {
    name: '',
    name2: '其他管理费用',
    amount1: 'costOtherOne',
    amount2: 'costOtherTwo',
    amount3: 'costOtherThree',
  }, {
    name: '财务费用',
    name2: '利息支出',
    amount1: 'costInterestOne',
    amount2: 'costInterestTwo',
    amount3: 'costInterestThree',
  }, {
    name: '二、营业利润',
    name2: '',
    amount1: 'profitOperatingOne',
    amount2: 'profitOperatingTwo',
    amount3: 'profitOperatingThree',
  }, {
    name: '减：所得税费用（按25％计算）',
    name2: '',
    amount1: 'taxIncomeOne',
    amount2: 'taxIncomeThree',
    amount3: 'taxIncomeTwo',
  }, {
    name: '三、净利润',
    name2: '',
    amount1: 'profitOne',
    amount2: 'profitTwo',
    amount3: 'profitThree',
  }, {
    name: '备注：员工薪酬包括企业主薪酬和职工薪酬，本计划书所提到的员工薪酬都符合该条件。',
    name2: '',
    amount1: '',
    amount2: '',
    amount3: '',
  },
])
//表格合并
const objectSpanMethod = ({ row, colunm, rowIndex, columnIndex }) => {
  if (!row.name2) {
    if (rowIndex == 17) {
      if (columnIndex == 0) {
        return [1, 5];
      } else {
        return [0, 0];

      }
    }
    if (columnIndex == 0) {
      return {
        rowspan: 1,
        colspan: 2,
      }
    } else if (columnIndex == 1) {
      return [0, 0];
    }

  }
  if (row.name == '管理费用') {
    if (columnIndex == 0) {
      return {
        rowspan: 6,
        colspan: 1,
      }
    }
  }
  if (row.name == '') {
    if (columnIndex == 0) {
      return [0, 0];
    }
  }

}
</script>

<style>
</style>