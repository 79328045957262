<template>
  <el-form :model="form" label-width="160px" :disabled='isPlanConfirm'>
    <el-form-item label="营销计划">
      <el-input v-model="form.plan" :autosize="{ minRows: 4, }" type="textarea"
        placeholder="在销售网络、销售渠道、设立代理商、分销商方面的策略；在广告促销方面的策略；在产品销售价格方面的策略；在建立良好销售队伍方面的策略；如何保证销售策略具体实施的有效性；对销售队伍采取什么样的激励机制等。" />
    </el-form-item>
    <el-form-item label="竞争分析">
      <el-input v-model="form.opponent" :autosize="{ minRows: 4, }" type="textarea"
        placeholder="列出在本公司目标市场中的1-3个主要竞争者；分析竞争者的优势和劣势。" />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit">暂存</el-button>

    </el-form-item>
  </el-form>
</template>

<script setup>
import { ref, reactive, getCurrentInstance } from 'vue'
import { detailManagement, submitManagement } from '@/api/cyds';
import { ElMessage, ElMessageBox } from 'element-plus'
const props = defineProps({
  PlanEntrepreneurshipId: {
    type: String,
    default: ''
  }, isPlanConfirm: {
    type: String,
    default: ''
  },
})
const form = reactive({})
console.log(props)
detailManagement({
  raceId: props.PlanEntrepreneurshipId
}).then(res => {
  console.log(res)
  if (res.data) {
    Object.assign(form, res.data)
  }
})
const onSubmit = () => {
  submitManagement(form).then(res => {
    if (res.code == 200) {
      detailManagement({
        raceId: props.PlanEntrepreneurshipId
      }).then(res => {
        console.log(res)
        if (res.data) {
          Object.assign(form, res.data)
        }
      })
      ElMessage({
        message: '暂存成功.',
        type: 'success',
      })
    } else {
      ElMessage({
        message: '参数错误.',
        type: 'error',
      })
    }
  })
}
</script>

<style>
</style>