<template>
  <el-form :model="form" label-width="160px" :disabled='isPlanConfirm'>
    <el-form-item label="市场定位">
      <el-input v-model="form.positioning" :autosize="{ minRows: 4, }" type="textarea" placeholder="1.产品定位：侧重于产品实体定位质量/成本/特征/性能/可靠性/实用性/款式
2.竞争定位：确定企业相对与竞争者的市场位置
3.消费者定位：确定企业的目标顾客群 ，指你究竟想要把产品卖给谁？" />
    </el-form-item>
    <el-form-item label="目标客户">
      <el-input v-model="form.customer" placeholder="可以按照客户年龄、地域、收入、偏好、消费习惯等分类。" />
    </el-form-item>
    <el-form-item label="市场预测（市场占有率）">
      <el-input v-model="form.forecast" :autosize="{ minRows: 3, }" type="textarea"
        placeholder="在已有“市场机会”的基础上着重分析市场容量等市场需求情况及其变化趋势；企业提供的产品或服务所占的市场份额。" />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit">暂存</el-button>

    </el-form-item>
  </el-form>
</template>

<script setup>
import { ref, reactive, getCurrentInstance } from 'vue'
import { getetailMarket, submitMarket } from '@/api/cyds';
import { ElMessage, ElMessageBox } from 'element-plus'
const props = defineProps({
  PlanEntrepreneurshipId: {
    type: String,
    default: ''
  }, isPlanConfirm: {
    type: String,
    default: ''
  },
})
const form = reactive({})
console.log(props)
getetailMarket({
  raceId: props.PlanEntrepreneurshipId
}).then(res => {
  console.log(res)
  if (res.data) {
    Object.assign(form, res.data)
  }
})
const onSubmit = () => {
  submitMarket(form).then(res => {
    if (res.code == 200) {
      getetailMarket({
        raceId: props.PlanEntrepreneurshipId
      }).then(res => {
        console.log(res)
        if (res.data) {
          Object.assign(form, res.data)
        }
      })
      ElMessage({
        message: '暂存成功.',
        type: 'success',
      })
    } else {
      ElMessage({
        message: '参数错误.',
        type: 'error',
      })
    }
  })
}
</script>

<style>
</style>