<template>
  <el-form :model="form" label-width="160px" :disabled='isPlanConfirm'>
    <el-form-item label="公司项目名称">
      <el-input v-model="form.teamName" />
    </el-form-item>
    <el-form-item label="注册资金(万元)">
      <el-input v-model="form.teamMoney" />
    </el-form-item>
    <el-form-item label="公司类型">
      <el-radio-group v-model="form.teamType">
        <el-radio label="有限责任公司" />
        <el-radio label="个体工商户" />
        <el-radio label="个人独资企业" />
        <el-radio label="合伙企业" />
        <el-row>
          <el-col :span="3">
            <el-radio label="其他" />
          </el-col>
          <el-col :span="21">
            <el-input v-model="form.teamTypeDesc" />
          </el-col>
        </el-row>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="产品/服务概况">
      <el-input v-model="form.teamProduct" :autosize="{ minRows: 4, }" type="textarea"
        placeholder="描述经营项目的创意来源与可行性，突出产品与服务的新颖性、独特性和可行性，目前所处发展阶段、与国内外同行业其它公司同类技术、产品及服务的比较，本公司技术、产品及服务的新颖性、先进性和独特性，如拥有的专门技术、版权、配方、品牌、销售网络、许可证、专营权、特许权经营等行业优势。" />
    </el-form-item>
    <el-form-item label="市场机会">
      <el-input v-model="form.marketChance" :autosize="{ minRows: 2, }" type="textarea"
        placeholder="指已经出现或即将出现在市场上，但未得到实现或完全实现的市场需求。" />
    </el-form-item>
    <el-form-item label="商业模式（盈利模式）">
      <el-input v-model="form.profitModel" :autosize="{ minRows: 4, }" type="textarea"
        placeholder="即盈利的模式，企业从哪里获得收入，获得收入的形式有哪几种？客户是谁？卖什么产品服务给客户？为客户带来什么价值？竞争壁垒是什么？此部分也可以谈谈企业的盈利渠道，即企业从哪里获得收入，获得收入的形式有哪几种？" />
    </el-form-item>
    <el-row>
      <el-col :span="12">
        <el-form-item label="投资额（元）">
          <el-input type='number' v-model="form.investment" />

        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="投资收益率（第一年）">
          <el-input v-model="form.investmentYield" type='number'>
            <template #append>%</template>
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item label="预期净利润（税后利润）" label-width="180px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="第一年" label-width="60px">
            <el-input type='number' v-model="form.profitOne" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="第二年" label-width="60px">
            <el-input type='number' v-model="form.profitTwo" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="年增长率" label-width="80px">
            <el-input type='number' v-model="form.profitTwoRate">
              <template #append>%</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="第三年" label-width="60px">
            <el-input type='number' v-model="form.profitThree" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="年增长率" label-width="80px">
            <el-input type='number' v-model="form.profitThreeRate">
              <template #append>%</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          投资收益率＝净利润÷总投资额×100%
        </el-col>
        <el-col :span="24">
          预期净利润－第一年：见经营第一年利润表
        </el-col>
        <el-col :span="24">
          此表中“总投资额”项的金额等于资金需求合计
        </el-col>
      </el-row>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit">暂存</el-button>

    </el-form-item>
  </el-form>
</template>

<script setup>
import { ref, reactive, getCurrentInstance } from 'vue'
import { getdetailExecute, submitExecute } from '@/api/cyds';
import { ElMessage, ElMessageBox } from 'element-plus'
const props = defineProps({
  PlanEntrepreneurshipId: {
    type: String,
    default: ''
  },
  isPlanConfirm: {
    type: String,
    default: ''
  },
})
const form = reactive({})
console.log(props)
getdetailExecute({
  raceId: props.PlanEntrepreneurshipId
}).then(res => {
  console.log(res)
  if (res.data) {
    Object.assign(form, res.data)
  }
})
const onSubmit = () => {
  submitExecute(form).then(res => {
    if (res.code == 200) {
      getdetailExecute({
        raceId: props.PlanEntrepreneurshipId
      }).then(res => {
        console.log(res)
        if (res.data) {
          Object.assign(form, res.data)
        }
      })
      ElMessage({
        message: '暂存成功.',
        type: 'success',
      })
    } else {
      ElMessage({
        message: '参数错误.',
        type: 'error',
      })
    }
  })
}
</script>

<style>
</style>