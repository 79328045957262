<template>
  <el-form :model="form" label-width="160px" :disabled='isPlanConfirm'>
    <el-table :data="list" border style="width: 100%; margin-top: 20px">
      <el-table-column prop="title" label="创业风险">

      </el-table-column>
      <el-table-column prop="fenxi" label="分析">
      </el-table-column>
      <el-table-column prop="value" label="对策">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <el-input v-model="form[scope.row.value]" />
          </div>
        </template>
      </el-table-column>
    </el-table>
    注：只需要填写本企业涉及到的风险
    <el-form-item>
      <el-button type="primary" @click="onSubmit">暂存</el-button>

    </el-form-item>
  </el-form>
</template>

<script setup>
import { ref, reactive, getCurrentInstance } from 'vue'
import { detailRisk, submitRisk } from '@/api/cyds';
import { ElMessage, ElMessageBox } from 'element-plus'
const props = defineProps({
  PlanEntrepreneurshipId: {
    type: String,
    default: ''
  }, isPlanConfirm: {
    type: String,
    default: ''
  },
})
const form = reactive({})
const list = reactive([
  {
    title: '行业风险',
    fenxi: '指行业的生命周期、行业的波动性、行业的集中程度。',
    value: 'industry',
  },
  {
    title: '政策风险',
    fenxi: '指因国家宏观政策（如货币政策、财政政策、行业政策等）发生变化，导致市场价格波动而产生风险。',
    value: 'policy',
  },
  {
    title: '市场风险',
    fenxi: '市场风险涉及的因素有：市场需求量、市场接受时间、市场价格、市场战略等。',
    value: 'market',
  },
  {
    title: '技术风险',
    fenxi: '指企业产品创新过程中技术不确定性、前景的不确定性、技术效果的不确定性、技术寿命的不确定性。',
    value: 'technology',
  },
  {
    title: '资金风险',
    fenxi: '资金风险主要有两类：一是缺少创业资金风险，二是融资成本风险。',
    value: 'money',
  },
  {
    title: '管理风险',
    fenxi: '企业经营过程中的风险，如管理者素质风险、决策风险、组织风险、人才风险等。',
    value: 'manage',
  },
  {
    title: '环境风险',
    fenxi: '指社会、政治、政策、法律环境变化或由于意外灾害发生而造成失败的可能性。',
    value: 'environment',
  },
  {
    title: '其他风险',
    fenxi: '',
    value: 'other',
  }
])
console.log(props)
detailRisk({
  raceId: props.PlanEntrepreneurshipId
}).then(res => {
  console.log(res)
  if (res.data) {
    Object.assign(form, res.data)
  }
})
const onSubmit = () => {
  submitRisk(form).then(res => {
    if (res.code == 200) {
      detailRisk({
        raceId: props.PlanEntrepreneurshipId
      }).then(res => {
        console.log(res)
        if (res.data) {
          Object.assign(form, res.data)
        }
      })
      ElMessage({
        message: '暂存成功.',
        type: 'success',
      })
    } else {
      ElMessage({
        message: '参数错误.',
        type: 'error',
      })
    }
  })
}
</script>

<style>
</style>